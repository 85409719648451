<template>
  <div class="empty">
    <p class="empty-info">此功能暂未开放</p>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.empty {
  background-color: white;
  color: black;
  font-size: 28px;
  display: flex;
  flex-direction: column;
  align-items: center;

  &-info{
    margin-top: 150px;
  }
}
</style>